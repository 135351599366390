<template>
  <div v-if="datos">
    <div class="titulo">
        {{datos.ID}}
        <button @click="eliminar">Eliminar</button>
        <button @click="facturar">Facturar</button>
    </div>
    <div>
        <label for="general">General</label>
        <label for="facturas">Facturas</label>
        <label for="campo">Campo</label>
    </div>
    <div style="display:none">
        <input id="general" type="radio" value="general" v-model="activo">General
        <input id="presupuesto" type="radio" value="presupuesto" v-model="activo">Presupuesto
        <input type="radio" id="facturas" value="facturas" v-model="activo">Facturas
        <input type="radio" id="campo" value="campo" v-model="activo">Campo
    </div>
    <PresuGeneral v-if="activo==='general'" :datos="datos"/>
    <form v-if="activo==='presupuesto'">
        Hola buenas tardes aqui viene el detalle de presupuesto
    </form>
    <PresuFacturas v-if="activo==='facturas'" :obra="datos.ID"/>
    <form v-if="activo==='campo'">
        Aqui viene el listado de dias que se ha realizado trabajo de campo
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import PresuGeneral from '@/components/PresuGeneral.vue'
import PresuFacturas from '@/components/PresuFacturas.vue'
export default {
    data: function () {
        return {
            activo: 'general',
            tabla: 'presupuestos',
            datos: null,
            id: this.$route.params.id
        }
    },
    components: {
        PresuGeneral,
        PresuFacturas
    },
    computed: {
        ...mapState(['detalles','listas','cache','tablas']),
    },
    methods: {
        ...mapMutations(['añadirpresupuesto']),
        ...mapActions(['cargardetalle','cambiosdetalle','api','cargartabla','cargarempresas']),
        async eliminar(){
            const res = await this.api([{'campo':'tabla','valor':'presupuestos'},{'campo':'ID','valor':this.id},'eliminar'])
            alert(res)
            if(res==='Presupuesto eliminado correctamente'){
                this.$router.push('/presupuestos')
            }
        },
        async facturar(){
            const res = await this.api([{campo:'tabla',valor:'facturas_geo'},{campo:'OBRA',valor:this.id},'nuevoregistro'])
            this.$router.push('/detallefacturas_geo/'+res[0]['id'])
        }
    },
    async beforeMount(){
        if(!this.tablas[this.tabla]){
            await this.cargartabla([this.tabla,this.tabla])
        }
        await this.cargarempresas()
        const id = this.id
        this.datos = this.tablas[this.tabla].filter(
            function(e) {
                if(e['ID']){
                    return e['ID']===id
                }
            }
        )[0]
        if(!this.datos){
            this.$router.push('/'+this.tabla)
        }
    }
}
</script>

<style scoped>
.titulo{
    font-size: 180%;
}
.general{
    margin: 1.5%;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5%;
}
.form > div label{
    display: block;
}
.form > div {
    margin: 1.5%;
}
.v-select{
    width: max-content;
    min-width: 100px;
}
</style>